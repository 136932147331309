import React, { useLayoutEffect } from 'react';
import './App.css';

const setWidgetLang = (lang) => {
  document.documentElement.lang = lang;
  window.history.pushState(window.location.origin, lang, `/${lang}/`);
};

const setWidgetLangToET = () => {
  setWidgetLang('et');
  window.location.reload();
};

const setWidgetLangToEN = () => {
  setWidgetLang('en');
  window.location.reload();
};

function App() {
  const displayLanguages = false;

  useLayoutEffect(() => {
    if (!displayLanguages) return;
    if (window.location.href === `${window.location.origin}/en/`) setWidgetLang('en');
    else setWidgetLang('et');
  }, []);

  return (
      <div className="Tulihand">
        <div className="wrapper">
          <div className="full-size-figure" aria-hidden="true">
            <video autoPlay loop muted style={{ width: '100vw' }}>
              <source
                  src="https://www.netgroup.com/wp-content/themes/netgroup/assets/dist/videos/video01_1920x1080.mp4"
                  type="video/mp4"
              />
            </video>
          </div>
          <header className="Tulihand-header">
            <div className="container-fluid">
              {displayLanguages &&
                  <nav className="main-header__lang">
                    <ul className="ld-lang-switch">
                      <div
                          className=""
                          onClick={setWidgetLangToET}
                          onKeyUp={setWidgetLangToET}
                          role="button"
                          tabIndex="0"
                      >
                        <a href={() => {
                        }} title="et" className="et">et</a>
                      </div>
                      <div
                          className=""
                          onClick={setWidgetLangToEN}
                          onKeyUp={setWidgetLangToEN}
                          role="button"
                          tabIndex="0"
                      >
                        <a href={() => {
                        }} title="en" className="en">en</a>
                      </div>
                    </ul>
                  </nav>}
            </div>
          </header>
          <div className="Tulihand-body">
            <div className="featured-logos">
              <img
                  src="https://netgroup.com/wp-content/uploads/2021/10/netgroup-logo-red.svg"
                  alt="Net Group logo"
                  width="191"
                  height="53"
              />
            </div>
          </div>
        </div>
      </div>
  );
}

export default App;
